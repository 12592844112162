<template>
	<div :class="themeClass" v-loading="pageLoading">
		<div class="margin-t-10">
			<div class="font-size18 font-weight700 bg-fff ">
				<div class="my-invoice-text padding-l-14">我的发票</div>
			</div>
			<div class="information-content  bg-fff ">
				<div class="bgc-pink flex-row">
					<i class="el-icon-s-opportunity flex-row message-box-i font-size14"></i>
					<div class="flex-row attestat_box font-size13">
						普通发票默认邮寄方式为电子邮件
					</div>
				</div>
				<div class="flex-row-space-between-center margin-t-6 padding-lr-15">
					<div class="flex-row">
						<div class="font-size15 font-weight700 business-info">
							发票信息
						</div>
						<div class="flex-row">
							<div v-if="this.fApproveStatus==0 && this.fStatus==0" class="padding-l-10 colorred ">
								未审核
							</div>
							<div v-if="this.fApproveStatus==1 && this.fStatus==0" class="padding-l-10 colorff9900">
								审核中
							</div>
							<div v-if="this.fApproveStatus==254 && this.fStatus==1" class="padding-l-10 color-theme">
								审核通过
							</div>
						</div>
					</div>
					<div class="changbtn-box text-center flex-row">
						<!-- <span v-if="this.invoiceType == 1" class="changbtn font-color-fff font-size14" @click="NewInvoice(1)">添加发票信息</span>
						<span v-if="this.invoiceType == 2" class="changbtn font-color-fff font-size14" @click="EditInvoice(2)">编辑发票信息</span> -->
						<!-- <span v-if="this.invoiceType != 3" class="changbtn font-color-fff font-size14" @click="ChangeInvoice(3)">变更发票信息</span> -->
					</div>
				</div>
				<div class="padding-lr-15 padding-b-50">
					<el-form ref="form" :model="form" label-width="90px" :label-position="labelPosition">
						<el-form-item label="发票类型">
							{{ form.fInvoiceClassName }}
						</el-form-item>
						<el-form-item label="发票抬头：">
							{{ form.fUnitName }}
						</el-form-item>
						<el-form-item :label="getUserInfo.fCommerceTypeID=='1'?'身份证号：':'企业税号：'">
							{{ form.fTaxAccounts }}
						</el-form-item>
						<div v-if="getUserInfo.fCommerceTypeID=='2'">
							<el-form-item label="注册地址：">
								{{ form.fAddress }}
							</el-form-item>
							<el-form-item label="公司电话：">
								{{ form.fPhone }}
							</el-form-item>
							<el-form-item label="开户银行：">
								{{ form.fBankNames }}
							</el-form-item>
							<el-form-item label="银行账户：">
								{{ form.fBankAccounts }}
							</el-form-item>
						</div>
					</el-form>
					<div v-for="(item,index) of imgArr" :key="index">
						<div class="annex-text">{{item.ruleName}}</div>
						<div class="annex-imgbox">
							<el-image style="width: 100%; height: 100%" :src="item.url" :fit="fit"></el-image>
						</div>
					</div>
					<el-divider></el-divider>
					<div class="flex-row-space-between-center">
						<div class="flex-row">
							<div class="font-size15 font-weight700 business-info">
								发票收件信息
							</div>
						</div>
						<div class="changbtn-box text-center flex-row">
							<span v-if="this.fAddressID == 1" class="changbtn font-color-fff font-size14" @click="AddInvoice(1)">新增发票收件信息</span>
							<span v-if="this.fAddressID == 2" class="changbtn font-color-fff font-size14" @click="AddInvoice(2)">变更发票收件信息</span>
						</div>
					</div>
					<el-form ref="form1" :model="form1" label-width="90px" :label-position="labelPosition">
						<div v-if="this.fAddressID == 2">
							<el-form-item label="收件邮箱：">
								{{ form1.feMail }}
							</el-form-item>
							<el-form-item label="邮寄地址：">
								{{ form1.fFullArea|formatLineString }}
							</el-form-item>
							<el-form-item label="收件姓名：">
								{{ form1.fLinkman }}
							</el-form-item>
							<el-form-item label="联系电话：">
								{{ form1.fLinkPhone }}
							</el-form-item>
							<el-form-item label="详细地址：">
								{{ form1.fAddress }}
							</el-form-item>
						</div>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import uploadFile from "@/utils/uploadFile";
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				pageLoading: false,
				fApproveStatus: 0, //发票id
				fAddressID: 0, //地址id
				fStatus: 0,
				uploadFileAshx: "",
				invoiceType: '', //1为新增，2为编辑，3为变更
				fit: "cover",
				url: "",
				labelPosition: "left",
				imgArr: [],
				form: {
					// fInvoiceClassName: "胖小宝",//发票类型
					// fUnitName: "乌鲁木齐恒河久远信息技术有限公司",//发票抬头
					// fTaxAccounts: "9144548554265552",//企业税号
					// fAddress: "新疆维吾尔自治区乌鲁木齐市天山区",//注册地址
					// fPhone: "13639956535",//公司电话
					// fBankNames:"中国银行",//开户行信息
					// fBankAccounts:"64123456987",//银行账号

				},
				form1: {
					// feMail: "1364428888@qq.com",//收件邮箱
					// fLinkman:"涨跌幅",//收件姓名
					// fLinkPhone:"15425489658",//联系电话
					// memberLever: "金卡会员",
					// userPhone: "13128883145"
				}
			};
		},
		computed: {
			...mapGetters(["getThemeName", 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			uploadActionURL() {
				return uploadFile.makeUploadActionURL(this.uploadDir, {
					ashx: this.uploadFileAshx,
				});
			},
		},
		mounted() {
			this.getInvoice();
			this.getAddress();
		},
		methods: {
			//获取发票信息
			getInvoice() {
				this.pageLoading = true;
				this.ApiRequestPostNOMess('/api/mall/ebcustomer/invoice-info/get', {}).then(
					result => {
						this.pageLoading = false;
						this.form = result.obj;
						this.fApproveStatus = result.obj.fApproveStatus;
						this.fStatus = result.obj.fStatus;
						let indexstr = this.uploadActionURL.lastIndexOf("/");
						let lefturl = this.uploadActionURL.substr(0, indexstr + 1);
						if (result.obj.annexes != null) {

							for (let i = 0; i < result.obj.annexes.length; i++) {
								if (result.obj.annexes[i].fPath != "") {
									for (let j = 0; j < result.obj.rules.length; j++) {
										if (result.obj.annexes[i].fAnnexTypeID == result.obj.rules[j].rule.fAnnexTypeID) {
											let objarr = {}
											objarr.url = result.obj.annexes[i].fullFPath;
											objarr.ruleName = result.obj.rules[j].rule.fRuleName;
											this.imgArr.push(objarr);
										}

									}

								}

							}
						}

						// if (result.obj.fInvoiceInfoID === null) {
						// 	this.invoiceType = 1;
						// 	//console.log("没有发票，要新增发票");
						// } else if (this.invoiceType != null && result.obj.fStatus == 0 && result.obj.fApproveStatus == 0) {
						// 	this.invoiceType = 2;//未审核，可编辑状态
						// }else if(this.invoiceType != null && result.obj.fStatus == 0 && result.obj.fApproveStatus == 1){
						// 	this.invoiceType = 3;//审核中，可读状态
						// }else if(this.invoiceType != null && result.obj.fStatus == 1 && result.obj.fApproveStatus == 254){
						// 	this.invoiceType = 4;//已审核，可变更
						// }
						//console.log(this.invoiceType,result.obj.fStatus,result.obj.fApproveStatus,"this.invoiceTypethis.invoiceType");
					},
					rej => {
						this.pageLoading = false;
					}
				);
			},
			//获取收货地址信息
			getAddress() {
				this.pageLoading = true;
				this.ApiRequestPostNOMess('/api/mall/eb-customer/address/get-invoice-address', {}).then(
					result => {
						this.pageLoading = false;
						this.form1 = result.obj;
						for (let i in this.form1) {
							if (result.obj[i] == "string") {
								result.obj[i] = "";
							}
						}
						if (result.obj.fAddressID === null) {
							this.fAddressID = 1;
						} else {
							this.fAddressID = 2;
						}
					},
					rej => {
						this.pageLoading = false;
					}
				);

			},
			/* NewInvoice(){
				this.$router.push({path: "/about/NewInvoice"});
			},
			EditInvoice(pageTitle) {
				this.$router.push({
					path: "/about/EditInvoice",
					query: {
						pageTitle: pageTitle
					},
				});
			}, */
			// ChangeInvoice(pageTitle){
			// 	this.$router.push({
			// 		path: "/PersonalContent/ChangeInvoice",
			// 		query: {
			// 			pageTitle: pageTitle
			// 		},
			// 	});
			// },
			AddInvoice(pageTitle) {
				this.$router.push({
					path: "/PersonalContent/AddInvoice",
					query: {
						pageTitle: pageTitle
					},
				});
			},
		},
		filters: {
			//斜线转换为空
			formatLineString: function(val) {
				if (val != undefined) {
					let reg = /\\/g;
					let replaceAfter = val.replace(reg, ' ');
					return replaceAfter;
				}

			}

		},

	};
</script>
<style lang="scss" scoped>
	.colorred {
		color: #ff0000;
	}

	.colorff9900 {
		color: #ff9900;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.information-content {
		width: 917px;
		height: auto;

		.bgc-pink {
			height: 30px;
			background: #fff7ee;

			.message-box-i {
				margin: 9px 5px 9px 15px;
				color: #ff9900;
			}

			.attestat_box {
				width: 100%;
				line-height: 30px;
				color: #ff9900;
				text-align: left;
			}
		}

		.changbtn-box {
			width: 160px;
			height: 40px;
			/* background: #0173fe;
			border-radius: 2px; */



			.changbtn {
				width: 160px;
				height: 40px;
				background: #0173fe;
				border-radius: 2px;
				margin: 0 auto;
				line-height: 40px;

				@include themify($themes) {
					background: themed("themes_color");
				}
			}

			.changbtn:hover {
				cursor: pointer;
			}

			.hideBtn {
				display: none;
			}
		}

		/* .changbtn-box:hover {
			cursor: pointer;
		} */

		.annex-imgbox {
			width: 110px;
			height: 80px;
			margin-top: 14px;
		}

		.annex-imgbox:hover {
			cursor: pointer;
		}
	}
	.padding-l-14{
		padding-left: 14px;
	}

	.business-info {
		padding: 26px auto;
	}

	.annex-text {
		margin: 7px auto;
		color: #606266;
	}

	.my-invoice-text {
		line-height: 37px;
	}

	::v-deep .el-form-item {
		margin-bottom: 0;
	}

	::v-deep .el-form-item__label,
	::v-deep .el-form-item__content {
		line-height: 33px;
	}

	::v-deep .el-divider--horizontal {
		margin: 37px auto 5px auto;
	}

	::v-deep .el-divider {
		background-color: #F2F2F2;
	}
</style>
